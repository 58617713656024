










































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { formatDate } from "@/lib/date";
import { ContractDocumentsEDOByContract } from "@/models/contract";
import XDataTable from "../hoc/Table.vue";
import ActionDropdownEDO from "../ActionDropdownEDO.vue";
import XButton from "../SimpleButton.vue";

@Component({
  components: {
    XDataTable,
    ActionDropdownEDO,
    XButton,
  },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({ contractId: "contract/id" }),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
  },
})
class DocumentEDOTable extends Vue {
  [x: string]: any;

  @Prop({ required: true }) documents!: ContractDocumentsEDOByContract[];

  headers = [
    { text: "Дата", value: "date", width: "12.5%" },
    { text: "Имя файла", value: "name", width: "30%" },
    { text: "Примечание", value: "comment", width: "30%" },
    { text: "Тип документа", value: "type", width: "22.5%" },
    { text: "Статус", value: "status", width: "5%" },
  ];

  docKeys = [
    "месяц",
    "имяфайла",
    "примечание",
    "типдокумента",
    "состояние",
  ];

  selectedAll = false;
  selectedRows: boolean[] = [];

  public get totalUsedDocKeys(): number {
    return !this.currentDocTypeBySenderStatusIndex ? this.docKeys.length : 4;
  }

  public get preparedHeaders() {
    return [
      ...this.headers.slice(0, this.totalUsedDocKeys),
      { text: "", value: "action", width: "7.5%" },
    ];
  }

  public get preparedDocuments() {
    const requiredKeys = this.docKeys.slice(0, this.totalUsedDocKeys);

    return this.documents.map((document) => {
      const docValues = requiredKeys.map((keyByTd) => {
        return Array.isArray(keyByTd)
          ? keyByTd.map((key) => document[key])
          : document[keyByTd];
      });

      // Пустая строка, как последний элемент массива позволяет использовать дропдаун
      // в каждой заключительной ячейке строки таблицы.
      return [...docValues, ""];
    });
  }

  @Emit()
  public propagateDocumentEvt(evtName: string, index: number) {
    this.$emit(evtName, this.documents[index]["$номерЗаписи"]);
  }
}

export default DocumentEDOTable;
