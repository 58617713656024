














































































import { Component, Mixins } from "vue-property-decorator";
import { ContractDocumentsEDOByContract } from "@/models/contract";
import Page from "@/components/for-page-grid/Page.vue";
import ContractDocumentEDOTable from "@/components/for-documents-view/ContractDocumentEDOTable.vue";
import ContractDocGetterDialog from "@/components/for-documents-view/ContractDocGetterDialog.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import { mapGetters } from "vuex";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";

@Component({
  components: {
    Page,
    ContractDocumentEDOTable,
    ContractDocGetterDialog,
    XNotice,
  },
  computed: {
    ...mapGetters("contract", { contractId: "id" })
  },
})
class ContractEDODocuments extends Mixins(AppApiMixin, DocGetterMixin) {
  documentList: ContractDocumentsEDOByContract[] = [];
  currentDocId = -1;
  currentDocName = '';
  currentDocStatus = -1;

  // Состояния всплывающих элементов страницы.
  showDocSendingDialog = false;
  useDocSendingDialogNotice = false;
  showDocSubscribeDialogNotice = false;
  targetEmail = "";

  showDocDownloadingDialog = false;

  public mounted() {
    const { contractId } = this;
    this.getDocData(contractId);
  }

  public getDocData(contractId) {
    this.getContractDocumentsEDOByContract(
      {contractId: contractId},
      (documentList: ContractDocumentsEDOByContract[]) => {
        this.documentList = documentList;
      }
    );
  }

  public closeAllDialogs() {
    this.showDocSendingDialog = false;
    this.showDocDownloadingDialog = false;
    this.showDocSubscribeDialogNotice = false;

    this.targetEmail = "";
    this.currentDocId = -1;
    this.currentDocName = "";
  }

  public getDocName() {
    return this.documentList.find((doc) => {
      return doc.$номерЗаписи === this.currentDocId;
    })?.["тип документа"] || '';
  }

  public getDocStatus() {
    return this.documentList.find((doc) => {
      return doc.$номерЗаписи === this.currentDocId;
    })?.["состояние"] || '';
  }

  public setDocStatus() {
    const { contractId } = this;

    this.setContractStatusEDO({
      contractId:contractId,
      documentId:this.currentDocId,
      contractStatus:this.currentDocStatus
    })
      .then((response) => {
        if(response) {
          this.showDocSubscribeDialogNotice = true;
          this.getDocData(contractId);
        }
      })
      .catch((_) => (this.setErrorMessage('Ошибка установления статуса документа')));
  }
}

export default ContractEDODocuments;
