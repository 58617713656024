var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{staticClass:"data-table_type_documents",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedDocuments,"use-item-slot":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var ref_item = ref.item;
var index = ref_item.index;
var item = ref_item.item;
return [_c('tr',{class:[
        'data-table__text',
        'data-table__text_cursor_default',
        _vm.selectedRows[index] && 'data-table__text_active_base' ]},_vm._l((item),function(value,header){return _c('td',{key:("" + header)},[(header === 'date')?_c('div',{staticClass:"d-flex flex-column justify-start"},[_c('span',{staticClass:"secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")])]):(['name', 'comment', 'type', 'desc', 'status'].indexOf(header) >= 0)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('action-dropdown-e-d-o',{on:{"send":function($event){return _vm.propagateDocumentEvt($event, index)},"download":function($event){return _vm.propagateDocumentEvt($event, index)},"accept":function($event){return _vm.propagateDocumentEvt($event, index)},"cancel":function($event){return _vm.propagateDocumentEvt($event, index)}}})],1)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }