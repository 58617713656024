

















































import { Component, Mixins, Watch } from "vue-property-decorator";
import { DocumentsAsReqBody } from "@/models/app-api";
import { DocumentsByContract } from "@/models/contract";
import Page from "@/components/for-page-grid/Page.vue";
import DocumentTable from "@/components/for-documents-view/DocumentTable.vue";
import DocGetterDialog from "@/components/for-documents-view/DocGetterDialog.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    Page,
    DocumentTable,
    DocGetterDialog,
    XNotice,
  },
})
class Documents extends Mixins(AppApiMixin) {
  docsProps: DocumentsAsReqBody = {
    contractId: -1,
    type: -1,
    noPayment: -1,
    from: "",
    to: "",
  };
  documentList: DocumentsByContract[] = [];
  currentDocNumber = -1;

  // Состояния всплывающих элементов страницы.
  showDocSendingDialog = false;
  useDocSendingDialogNotice = false;
  targetEmail = "";

  showDocDownloadingDialog = false;

  @Watch("docsProps")
  public docsPropsChanged() {
    this.getDocumentsByContract(
      this.docsProps,
      (documentList: DocumentsByContract[]) => {
        this.documentList = documentList;
      }
    );
  }

  public closeAllDialogs() {
    this.showDocSendingDialog = false;
    this.showDocDownloadingDialog = false;

    this.currentDocNumber = -1;
    this.targetEmail = "";
  }
}

export default Documents;
